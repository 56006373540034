import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faStar } from '@fortawesome/free-solid-svg-icons'


import ReviewImg from '../../subassets/sabirshah.jpg';
import ReviewImg2 from '../../subassets/unnamed.png';
import ReviewImg3 from '../../subassets/unnamed (1).png';

const Review = () => {
  return (
    <section id='review' className='review'>
      <h4 className="tophead">what our clients say</h4>
      <div className="review-container">
        <div className="review-img">
          <img src={ReviewImg} alt="review" />
        </div>
        <div className="review-text">
          <div className="review-part1">
            <img src={ReviewImg2} alt="review" />
            <span>
              <h4>Babar Naeem</h4>
              <h6>Saidu Sharif, Swat</h6>
            </span>
          </div>
          <p>For your own good, Hire this Shah and Shah law associate's! An honest and professional group of lawyers, specialized in a variety of areas, who work to get what is required by you. They stand for justice and have esteemed integrity. Their attention to detail and knowledge of laws is unprecedented. Shah and shah Law Associates are an excellent choice and I feel confident to recommend them to all requiring persuasive legal services. Shah and shah Law Associate and Especially Sir sabir shah saib ! provides genuine and authentic legal information that gives your legal journey an incredible.</p>
          <div className="review-svg">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} color='gray' />
          </div>
          <div className="review-btns">
            <button><FontAwesomeIcon icon={faChevronLeft}/></button>
            <button>
            <FontAwesomeIcon icon={faChevronRight}/>
            </button>
          </div>
        </div>
        <div className="review-text">
          <div className="review-part1">
            <img src={ReviewImg3} alt="review" />
            <span>
              <h4>Salman Ahmed</h4>
              <h6>Saidu Sharif, Swat</h6>
            </span>
          </div>
          <p>The group a Shah & Shah Law Associates truly went above and beyond in handling my personal queries and milestones. They diligently explained every situation in detail to me and answered all of my questions. I had the pleasure of dealing with Adv Sabir Shah (adv Supreme Court) who, from the very start, was upfront and realistic regarding the situation and expectations. In the end the group maximized my learning and encouragement --for which I am very grateful. I was very fortunate to fall into the care of the Shah & Shah Law Associates and I would recommend them to anyone in need of a law firm to handle any kind of personal matters.</p>
          <div className="review-svg">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} color='gray' />
          </div>
          <div className="review-btns">
            <button><FontAwesomeIcon icon={faChevronLeft}/></button>
            <button>
            <FontAwesomeIcon icon={faChevronRight}/>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Review