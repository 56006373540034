import React from 'react'

import Lawyer1 from '../../subassets/sabirshah.jpg'
import Lawyer3 from "../../subassets/falaknaz'.jpg"
import Lawyer2 from '../../subassets/jamalshah.jpg'
import Lawyer4 from '../../subassets/buttsb.jpg'
import Lawyer5 from '../../subassets/lawyer.jpg'
import Lawyer6 from '../../subassets/ubaid.jpg'

const Attorney = () => {
  return (
    <section id='attorney' className='attorney'>
      <h4 className='tophead'>Our team</h4>

      <div className="attorney-cont">
        <div className="profile">
          <img src={Lawyer1} alt="lawyer"  loading='lazy'/>
            <div className="overlay">
            <h4>Sabir Shah</h4>
            <h5>Advocate Supreme Court of Pakistan</h5>
            <p>Sabir Shah is heading Shah & Shah Law Associates. He is an Advocate of the Supreme Court of Pakistan and is a member of Supreme Court Bar Association, Peshawar High Court Bar Association and District Bar Association, Swat.</p>
          </div>
          
        </div>
        <div className="profile">
          <img src={Lawyer2} alt="lawyer" loading='lazy' />
          <div className="overlay">
            <h4>Jamal Shah</h4>
            <h5>Advocate High Court</h5>
            <p>Jamal Shah is a Partner with the firm. He practicing since 2017 having attained his Bachelors of Law Degree from University of Peshawar.</p>
          </div>
        </div>
        <div className="profile">
          <img src={Lawyer3} alt="lawyer" style={{  }} loading='lazy' />
          <div className="overlay">
            <h4>Falak Naz Khan</h4>
            <h5>Advocate High Court</h5>
            <p>Senior Associate with the firm. He practicing since 2017 having attained his Bachelors of Law Degree from University of Peshawar. He has done his Masters in Management Sciences from Quaid-i-Azam University, Islamabad.</p>
          </div>
        </div>
        <div className="profile">
          <img src={Lawyer4} alt="lawyer" loading='lazy' />
          <div className="overlay">
            <h4>Aftab Hussain</h4>
            <h5>Advocate High Court</h5>
            <p>Associate with the firm. He practicing since 2018 having attained his Bachelors of Law Degree from Islamia College University, Peshawar.</p>
          </div>
        </div>
        <div className="profile">
          <img src={Lawyer5} alt="lawyer" loading='lazy' />
          <div className="overlay">
            <h4>Abbas Khan</h4>
            <h5>Paralegal</h5>
            <p>Member of paralegal staff with the firm.</p>
          </div>
        </div>
        <div className="profile">
          <img src={Lawyer6} alt="lawyer" loading='lazy' />
          <div className="overlay">
            <h4>Ubaid Khan</h4>
            <h5>Paralegal</h5>
            <p>Member of paralegal staff with the firm.</p>
          </div>
        </div>
        {/* <div className="profile">
          <img src={Lawyer6} alt="lawyer" loading='lazy' />
          <div className="overlay">
            <h4>James Will</h4>
            <p>We're proud that our law firm offers top quality</p>
          </div>
        </div> */}
      </div>
    </section>
  )
}

export default Attorney