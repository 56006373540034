import React from 'react'

const Experience = () => {
  return (
    <section id='experience' className='experience'>
      <h1 className='headline'>Providing Legal Service since 2006</h1>
      <p>
      we are ready to provide you with the best legal services in all fields of law, including but not limited to Civil law, Criminal law, Banking Law, Administrative law, Constitutional law, Family law, and Labour law, Corporate law as we believe that
YOUR trust in us is the foundation of our FIRM.
      </p>
      <div className='stats'>
        <div>
          <h4>2006 </h4>
          <h6>year of establishment</h6>
        </div>
        <div>
          <h4>547 </h4>
          <h6>cases won</h6>
        </div>
        <div>
          <h4>45+</h4>
          <h6>business partners</h6>
        </div>
        <div>
          <h4>60+</h4>
          <h6>trusting clients</h6>
        </div>
      </div>
    </section>
  )
}

export default Experience