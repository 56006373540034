import React from 'react'
import {motion} from 'framer-motion'
import { useAnimations } from './useAnimations'

import LadyJustice from '../../subassets/lady-justice.png'
import Button from './Button'
import Judge from '../../subassets/undraw_judge_katerina_limpitsouni_ny1q.png'

const Home = () => {
  const { transition, textReveal } = useAnimations();
  // })
  return (
    <>
      <section id='home' className='home'>
        <div className='home-text'>
          <motion.h1 
            variants={textReveal}
            initial='bananin'
            animate='bananon'
            transition={{...transition, delay: 0.3}}
          >Commitment.</motion.h1>
          <motion.h1 className='dent'
            variants={textReveal}
            initial='bananin'
            animate='bananon'
            transition={{...transition, delay: 0.5}}
          >
            Effectiveness.</motion.h1>
          <motion.h1 className='det' 
            variants={textReveal}
            initial='bananin'
            animate='bananon'
            transition={{...transition, delay: 0.7}}
            >
            Integrity.</motion.h1>
          <motion.h1 className='det1' 
            variants={textReveal}
            initial='bananin'
            animate='bananon'
            transition={{...transition, delay: 0.9}}
            >
            Excellence.</motion.h1>
          <motion.p
            variants={textReveal}
            initial='bananin'
            animate='bananon'
            transition={{...transition, delay: 0.9}}
          >A leading law firm providing professional service in the field of law</motion.p>
          <a href="#request">
          <Button title='Contact Us' />
          </a>
        </div>
        <div className='home-img'>
          <p id='glass'>we are ready to provide you with the best legal services in all fields of law, including but not limited to Civil law, Criminal law, Banking Law, Administrative law, Constitutional law, Family law, and Labour law, Corporate law as we believe that
YOUR trust in us is the foundation of our FIRM.</p>
          <img src={LadyJustice} alt="ladyjudge" />
        </div>
        <div className='home-judge'>
          <img src={Judge} alt="judge" />
        </div>
      </section>
    </>
  )
}

export default Home