import React from 'react'
import Nbp from '../../subassets/nbp.jpg'
import Nha from '../../subassets/nha.jpg'
import Sngpl from '../../subassets/SNGPL.png'
import tma from '../../subassets/tma.jpeg'
import bise from '../../subassets/bise.png'

const Trust = () => {
  return (
    <section id='trust' className='trust'>
      <h4 className="tophead">Trusted companies</h4>
      <p>Our law firm representing these companies in various legal issues and matters in the High Court and special courts as well.</p>
      <div className="companies-container">
        <div className="company">
          <img src={Nbp} alt="National Bank of Pakistan" style={{ height: '120px', width: '120px' }} />
          <h6>NBP</h6>
        </div>
        <div className="company">
          <img src={Nha} alt="National Bank of Pakistan" style={{ height: '120px', width: '120px' }} />
          <h6>NHA</h6>
        </div>
        <div className="company">
          <img src={Sngpl} alt="National Bank of Pakistan" style={{ height: '120px', width: '120px' }} />
          <h6>SNGPL</h6>
        </div>
        <div className="company">
          <img src={tma} alt="National Bank of Pakistan" style={{ height: '120px', width: '120px' }} />
          <h6>TMA Dergai</h6>
        </div>
        <div className="company">
          <img src={bise} alt="National Bank of Pakistan" style={{ height: '120px', width: '120px' }} />
          <h6>BISE Swat</h6>
        </div>
      </div>
    </section>
  )
}

export default Trust